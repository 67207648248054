import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreatePasswordService {
  #http = inject(HttpClient);

  constructor() { }



  changePassword(newpassword: string, token: string) {
    const mappedData = { "password": newpassword }
    return this.#http.post(this._changePasswordByUser(token), mappedData)
  }

  private _changePasswordByUser(token: string): string {
      return `${environment.baseURL}/${environment.endpoints.change_password}?token=${token}`
    }
}
