import { Component, OnInit, DestroyRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RequestStatus } from '@shared/interfaces/request-status.model';
import { faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { mergeMap, take,timer,Subscription } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { Router, ActivatedRoute} from '@angular/router';
import { passwordValidator } from '@shared/validators/password.validator';
import { ModalGenericComponent } from '@shared/components/modal-generic/modal-generic.component';
import { ModalIcon, ModalSettings } from '@shared/components/modal-generic/modal-model';
import {Auth, confirmPasswordReset, verifyPasswordResetCode} from "@angular/fire/auth";
import {FirebaseService} from "@shared/services/firebase.service";
import { MediaObserver } from '@angular/flex-layout';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { ROUTES_APP } from 'src/app/app.routes';
import { passwordsShouldDifferValidator } from '@shared/validators/passwordsShouldDiffer.validator';
import { CustomValidators } from '@shared/validators/validators';
import { CreatePasswordService } from './services/create-password.service';


@Component({
  selector: 'sho-session-create-password',
  templateUrl: './session-create-password.component.html',
  styleUrls: ['./session-create-password.component.scss']
})
export class SessionCreatePasswordComponent implements OnInit {
  

  form = this.formBuilder.nonNullable.group({
    /* email: ['', [Validators.email, Validators.required]],
    Password: ['', [Validators.required]], */
    newpassword: ['', [Validators.minLength(8), Validators.required, passwordValidator]],
    confirmPassword: ['', [Validators.required, passwordValidator]],
  },{ validators: [passwordsShouldDifferValidator, CustomValidators.MatchValidator('newpassword', 'confirmPassword')] });

  status: RequestStatus = 'init';
  showPasswordInit = false
  showPassword = false;
  showPasswordConfirm = false
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircleCheck = faCircleCheck;

  mostrarDiv = false;
  mostrarDivPageReal = true;
  mostrarDivPantallaReal = true;
  apiKey!: string
  mode!: string
  oobCode!: string;
  tokenParams!: string;
  subscriptions!: Subscription;
  pantallaPequeña = false;

  constructor(
    private router: Router,
    private dialog: Dialog,
    private formBuilder: FormBuilder,
    private _firebase: FirebaseService,
    private route: ActivatedRoute,
    private auth: Auth,
    private mediaObserver: MediaObserver,
    private _destroyRef: DestroyRef,
    private _createPassword: CreatePasswordService
  ) {

   this.route.queryParamMap.subscribe(async params => {
      const tokenParams = params.get('token');
      if (tokenParams) {
        this.tokenParams = tokenParams;
      }  
    });

  }

  ngOnInit(): void {

  this.mediaObserver.asObservable().pipe(takeUntilDestroyed(this._destroyRef)).subscribe((changes) => {
    changes.forEach((change) => {
      if (change.mqAlias === 'xs') {
        this.pantallaPequeña = true;
      } else {
        // Estás en una pantalla grande
        // Realiza acciones específicas para pantallas grandes
      }
    });
  });
    //this._verifyEmailFirebase();

      // Oculta el mensaje de éxito después de 3 segundos
      /* timer(3000).subscribe(() => {
        this.mostrarDiv = false;
      }); */
  }

  //metodo para abrir el modal del token expired
  openDialog (): void {
    const settings:ModalSettings = {
      tittle:"Este link ha expirado",
      paragraph:"Por favor vuelve a solicitar uno nuevo en la pantalla de inicio, te recordamos que no deben pasar más de 10 minutos desde el momento de la solicitud",
      paragraphSize:"w-[335px]",
      icon: ModalIcon.Error
    }

    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '450px',
      data: settings,
    });

    //dialogRef.closed.subscribe(() => this.router.navigateByUrl('/signin'));
  }

  openDialogGeneric (icon:ModalIcon, titulo:string, texto?: string): void {
    const settings:ModalSettings = {
      tittle: titulo,
      paragraph: texto,
      paragraphSize:"w-[335px]",
      icon: icon,
    }
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      disableClose: true,
      width: '450px',
      data: settings,
    });
  }

  //metodo para abrir el modal de la contraseña cuando está correcta.
  openDialogSuccess (): void {
    const settings:ModalSettings = {
      tittle:"La contraseña fue creada con éxito",
      paragraph:"Ingresa a la plataforma con tu correo electrónico y contraseña",
      paragraphSize:"w-[335px]",
      icon: ModalIcon.Success
    }

    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '450px',
      data: settings,
    });

    dialogRef.closed.subscribe(() => this.router.navigateByUrl(`/${ROUTES_APP.SIGNIN}`));
  }

  isNewPasswordValid ():boolean {
    return this.form.controls.newpassword.touched && this.form.controls.newpassword.invalid;
  }

  /* isPasswordValid():boolean {
    return this.form.controls.Password.touched && this.form.controls.Password.invalid;
  } */

  isPasswordsMatch(): boolean {
    return this.form.hasError('passwordsShouldNotMatch') && this.form.touched;
  }

  isConfirmPasswordValid():boolean {
    return this.form.controls.confirmPassword.touched && this.form.controls.confirmPassword.invalid;
  }

  /* isEmailValid(): boolean {
    return this.form.controls.email.touched && this.form.controls.email.invalid
  } */

  //metodo para cambiar la contraseña  al usuario.
  recovery() {
    if (this.form.valid) {
      this.status = 'loading';
      const { newpassword } = this.form.getRawValue();
      
      this._createPassword.changePassword(newpassword, this.tokenParams).subscribe({
        next: () => {
          // Manejo del éxito (200)
          this.openDialogSuccess();
          const redirection = this.pantallaPequeña
            ? ROUTES_APP.REDIRECTION
            : ROUTES_APP.SIGNIN;
  
          timer(3000).subscribe(() => {
            this.dialog.closeAll();
            this.router.navigateByUrl(`/${redirection}`);
          });
        },
        error: (error) => {
          // Manejo de errores con diferentes códigos de estado
          if (error.status === 401) {
            // Credenciales no válidas
            this.openDialogGeneric(
              ModalIcon.Warning,
              'Credenciales inválidas',
              'Por favor, verifica tus datos e inténtalo nuevamente.'
            );
          } else if (error.status === 400) {
            // Parámetros incorrectos
            this.openDialogGeneric(
              ModalIcon.Error,
              'Error de parámetros',
              'Los datos enviados no son correctos. Verifica e inténtalo nuevamente.'
            );
          } else {
            // Otro tipo de error
            this.openDialogGeneric(
              ModalIcon.Error,
              'Ha ocurrido un error',
              'Inténtalo nuevamente más tarde.'
            );
          }
          this.status = 'failed';
          console.error('Error en el cambio de contraseña:', error);
        },
      });
    } else {
      // Si el formulario no es válido, marca todos los campos como tocados
      this.form.markAllAsTouched();
    }
  }
  
  

}
