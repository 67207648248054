<main class="w-screen h-screen bg-white sm:bg-white relative flex flex-col">
    <!-- header -->
    <section>
      <app-logo-navbar-soho></app-logo-navbar-soho>
    </section>
    <!-- toast success  -->
    <section class="relative">
      <div *ngIf="mostrarDiv"
            id="toast-undo"
            class="absolute left-0 top-0 md:left-[110px] md:top-[60px] flex items-center
                   w-[414px] md:w-[446px] p-4 text-white bg-[#4AA785] rounded-lg shadow" role="alert">
        <div class="text-sm font-normal flex items-center">
          <fa-icon class="text-white text-lg" [icon]="faCircleCheck"></fa-icon>
          <p class="text-white ml-4 text-lg leading-tight">
            Felicidades, ingresa tu nueva contraseña
          </p>
        </div>
      </div>
    </section>
    <!-- formulario -->
    <section>
      <div class="w-full flex flex-col items-center mt-[80px] md:mt-[50px]">
        <h1 class="text-center text-xl font-bold pt-2 pb-[35px] text-[#122B36]">
          Crea tu nueva contraseña
        </h1>
        <form [formGroup]="form" class="w-[344px]"  (ngSubmit)="recovery()">

          <!-- <div>
            <div class="relative">
              <input type="text" id="input-mail" #nameInput formControlName="email" placeholder="Correo electrónico"
                type="email" class="peer autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]" [ngClass]="{'ctm-flt-inp-err': isEmailValid(), 'ctm-flt-inp': !isEmailValid() }" />
              <label for="input-mail" [ngClass]="{'ctm-flt-lbl-err': isEmailValid(), 'ctm-flt-lbl': !isEmailValid()}">
                Correo electrónico
              </label>
            </div>
            <div class="h-[18px] mt-1">
              <div *ngIf="isEmailValid()">
                <p *ngIf="form.controls.email.hasError('required')" class="font-medium text-red-500 text-xs  ml-1">
                  Este campo es obligatorio
                </p>
                <p *ngIf="form.controls.email.hasError('email')" class="font-medium text-red-500 text-xs mt-1 ml-1">
                  Este campo debe ser un correo electrónico.
                </p>
              </div>
            </div>
        
        
          </div> -->
          
          <!--verify password field-->
          <!-- <div class="relative mt-[20px]">
            <input
              id="Password"
              placeholder="Contraseña"
              formControlName="Password"
              [type]="showPasswordInit ? 'text' : 'password'"
              class="peer"
              [ngClass]="{'ctm-flt-inp-err': isPasswordValid(), 'ctm-flt-inp': !isPasswordValid()}"/>
            <label for="confirmPassword"
                [ngClass]="{'ctm-flt-lbl-err': isPasswordValid(), 'ctm-flt-lbl': !isPasswordValid()}">
                Contraseña
            </label>
            <button title="Contraseña"
              type="button"
              class="p-2 absolute right-2 bottom-0"
              (click)="showPasswordInit = !showPasswordInit">
              <fa-icon [icon]="showPasswordInit ? faEye : faEyeSlash"></fa-icon>
            </button>
          </div> -->
          <!--verify password field Errrors-->
          <!-- <div class="h-[45px] mt-1">
            <div>
                <p class="font-medium text-[#828282] text-xs ml-1">
                  Ingresa la contraseña asignada que te llegó a tu correo
                </p>
              </div>
            <div *ngIf="isPasswordValid()">
              <p  *ngIf="form.controls.Password.hasError('required')"
                  class="font-medium text-red-500 text-xs  ml-1">
                  Este campo es obligatorio
              </p>
            </div>
          </div> -->
  
          <!-- contraseña -->
           <!--password field-->
          <div class="relative">
            <input id="newpassword"
                placeholder="Nueva contraseña"
                formControlName="newpassword"
                [type]="showPassword ? 'text' : 'password'"
                class="peer"
                [ngClass]="{'ctm-flt-inp-err': isNewPasswordValid(), 'ctm-flt-inp': !isNewPasswordValid()}"/>
            <label for="newpassword"
                [ngClass]="{'ctm-flt-lbl-err': isNewPasswordValid(), 'ctm-flt-lbl': !isNewPasswordValid()}">
                Nueva contraseña
            </label>
            <button title="Contraseña"
                type="button"
                class="p-2 absolute right-2 bottom-0"
                (click)="showPassword = !showPassword">
              <fa-icon [icon]="showPassword ? faEye : faEyeSlash"></fa-icon>
            </button>
          </div>
          <!--password field errors-->
          <div class="h-[45px] mt-0">
            <p class="font-medium text-[#828282] text-xs ml-1">
                Debe contener una mayúscula, un número y 8 caracteres
            </p>
            <div *ngIf="isPasswordsMatch()">
                <p class="font-medium text-red-500 text-xs ml-1">
                      Las contraseña nueva no debe ser igual a la contraseña actual
                  </p>
            </div>
            <div *ngIf="isNewPasswordValid()">
              <p *ngIf="form.controls.newpassword.hasError('required')"
                  class="font-medium text-red-500 text-xs ml-1">
                  Este campo es obligatorio
              </p>
              <!-- <p  *ngIf="form.controls.newpassword.hasError('minlength')"
                  class="font-medium text-red-500 text-xs mt-1 ml-1">
                  Este campo debe tener más de 8 caracteres
              </p> -->
              <!-- <p  *ngIf="form.controls.newpassword.hasError('password-invalid')"
                  class="font-medium text-red-500 text-xs mt-1 ml-1">
                  Este campo debe contener una mayúscula, un número.
              </p> -->
            </div>
          </div>

          <!--verify password field-->
        <div class="relative mt-[0px]">
          <input
            id="confirmPassword"
            placeholder="Confirmar contraseña"
            formControlName="confirmPassword"
            [type]="showPasswordConfirm ? 'text' : 'password'"
            class="peer"
            [ngClass]="{'ctm-flt-inp-err': isConfirmPasswordValid(), 'ctm-flt-inp': !isConfirmPasswordValid()}"/>
          <label for="confirmPassword"
              [ngClass]="{'ctm-flt-lbl-err': isConfirmPasswordValid(), 'ctm-flt-lbl': !isConfirmPasswordValid()}">
              Confirmar contraseña
          </label>
          <button title="Contraseña"
            type="button"
            class="p-2 absolute right-2 bottom-0"
            (click)="showPasswordConfirm = !showPasswordConfirm">
            <fa-icon [icon]="showPasswordConfirm ? faEye : faEyeSlash"></fa-icon>
          </button>
        </div>
        <!--verify password field Errrors-->
        <div class="h-[45px] mt-1">
          <p class="font-medium text-[#828282] text-xs ml-1">
            Debe contener una mayúscula, un número y 8 caracteres
        </p>
          <div *ngIf="isConfirmPasswordValid()">
            <p  *ngIf="form.controls.confirmPassword.hasError('required')"
                class="font-medium text-red-500 text-xs  ml-1">
                Este campo es obligatorio
            </p>
            <!-- <p  *ngIf="form.controls.confirmPassword.hasError('minlength')"
                class="font-medium text-red-500 text-xs mt-1 ml-1">
                Este campo debe tener más de 8 caracteres.
            </p> -->
            <!-- <p  *ngIf="form.controls.newpassword.hasError('password-invalid')"
                class="font-medium text-red-500 text-xs mt-1 ml-1">
                Este campo debe contener una mayúscula, un número.
            </p> -->
          </div>
          <div *ngIf="form.controls.confirmPassword.touched">
            <p  *ngIf="form.hasError('mismatch')"
                class="font-medium text-red-500 text-xs ml-1">
                Las contraseñas deben coincidir
            </p>
          </div>
        </div>
  
          <!--submit button-->
          <app-btn [disabled]="this.form.invalid"  typeBtn="submit" color="success" [loading]="status === 'loading'"
                    [styles]="{height:'50px', background: '#146D81'}">
                    Crear contraseña
          </app-btn>
        </form>
  
      </div>
    </section>
  </main>
  
  
