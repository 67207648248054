import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuStatusSource = new BehaviorSubject<string | null>(null); // Estado inicial: sin menú activo
  menuStatus$ = this.menuStatusSource.asObservable(); // Observable que los componentes pueden escuchar

  // Método para actualizar el estado del menú
  setMenuStatus(menuName: string | null) {
    this.menuStatusSource.next(menuName);
  }
}

